<template>
    <div class="card card-custom">
        <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
                <h3 class="card-label">Создание статьи</h3>
            </div>
        </div>
        <form>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <BaseInput
                            type="text"
                            :label="'Название *'"
                            :error="errors.get('title')"
                            v-model="form.title"
                        />

                        <BaseSelect
                            type="text"
                            :label="'Тип *'"
                            :options="types"
                            v-model="form.type"
                        />
                        <BaseTextarea
                            rows="6"
                            :label="'Краткое описание'"
                            :error="errors.get('short_description')"
                            v-model="form.short_description"
                            hint="Максимальное количество символов: 255"
                        />
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>Изображение *</label>
                            <el-upload
                                class="avatar-uploader"
                                action="''"
                                :auto-upload="false"
                                list-type="picture"
                                accept=".jpeg, .jpg"
                                :show-file-list="false"
                                ref="upload"
                                :on-change="handleOnChangeFile"
                            >
                                <img
                                    v-if="form.original_name && !form.image"
                                    :src="imgSrc"
                                    class="avatar"
                                    style="width: 100%"
                                />

                                <img v-else-if="form.image" :src="imgSrc" class="avatar" />

                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>

                                <div
                                    slot="tip"
                                    class="el-upload__tip"
                                >Допускаются изображения с расширением .jpeg, .jpg, .png не более 2Mb</div>
                            </el-upload>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('image')"
                            >{{ errors.get('image') }}</div>
                        </div>
                    </div>
                </div>

                <editor
                    v-model="form.text"
                    maxlength="2000"
                    placeholder="Не больше 2000 символов"
                    rows="4"
                    :api-key="apiKey"
                    :init="{
                        language: 'ru',
                        toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | numlist bullist checklist | image link media',
                        plugins: 'image link advlist lists media',
                        file_picker_types: 'image',
                        images_upload_handler: uploadHandler
                    }"
                />

                <div class="form-group mt-5">
                    <div class="checkbox-list">
                        <label class="checkbox">
                            <input type="checkbox" v-model="form.active" />
                            <span></span>Отображать на сайте
                        </label>
                    </div>
                </div>

                <BaseInput
                    type="date"
                    class="col-3"
                    :label="'Дата публикации'"
                    :error="errors.get('published_at')"
                    v-model="publishedAt"
                />

                <el-upload
                    class="upload-demo"
                    action="https://jsonplaceholder.typicode.com/posts/"
                    :on-remove="handleRemove"
                    :on-change="changeFile"
                    :before-remove="beforeRemove"
                    multiple
                    :limit="3"
                    :file-list="fileList"
                    :auto-upload="false"
                >
                    <el-button size="small" type="primary">Прикрепить файл</el-button>
                    <div slot="tip" class="el-upload__tip">doc/pdf/xls файлы не более 2 Mb</div>
                </el-upload>

                <br />
                <h3>Seo</h3>

                <BaseInput
                    type="text"
                    :label="'SEO title'"
                    :error="errors.get('seo_title')"
                    v-model="form.seo_title"
                />

                <!--  <BaseInput
                    type="text"
                    :label="'SEO slug'"
                    :error="errors.get('seo_slug')"
                    v-model="form.seo_slug"
                />
                -->
                <BaseTextarea
                    rows="3"
                    :label="'SEO description'"
                    :error="errors.get('seo_description')"
                    v-model="form.seo_description"
                />
            </div>
            <div class="card-footer">
                <BaseButton @click.prevent="save()">Сохранить</BaseButton>
                <BaseButton btnClass="btn-secondary" @click.prevent="$emit('close')">Отменить</BaseButton>
            </div>
        </form>
    </div>
</template>

<script>

import { Errors } from '@/classes/Errors.js'
import Editor from '@tinymce/tinymce-vue';

export default {
    props: {
        id: [Number, String]
    },
    components: { Editor },
    computed: {
        imgSrc() {
            return this.form.image && typeof this.form.image == 'object'
                ? URL.createObjectURL(this.form.image)
                : this.form.images.medium
        },

        publishedAt: {
            get: function () {
                return this.$moment(this.form.published_at).format('YYYY-MM-DD')
            },
            set: function (val) {
                this.form.published_at = val
            }
        }
    },
    data() {
        return {
            apiKey: process.env.VUE_APP_TINIMCE_KEY,
            errors: new Errors(),
            dialogVisible: false,
            form: this.initForm(),
            types: [
                {
                    id: 'article',
                    title: 'Статья'
                },
                {
                    id: 'new',
                    title: 'Новость'
                },
                {
                    id: 'advert',
                    title: 'Объявление'
                },
                {
                    id: 'note',
                    title: 'Заметка'
                },
                {
                    id: 'event',
                    title: 'Событие'
                }
            ],
            fileList: [],
            files: []
        }
    },

    methods: {
        beforeRemove(file, fileList) {
            return this.$confirm(`Удаление файла ${file.name} ?`);
        },
        changeFile(file, fileList) {

            if (![
                'application/pdf',
                'application/msword',
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            ].includes(file.raw.type)) {
                return this.$notify({
                    type: 'warning',
                    message: 'Допускаются файлы с расширением .xls, .xlsx, .pdf, .doc, docx'
                })
            }

            if (file.raw.size > 2097152) {
                return this.$notify({
                    type: 'warning',
                    message: 'Допускаются файлы не более 2Mb'
                })
            }

            this.files.push(file)
        },
        handleRemove(file, fileList) {
            console.log(file);

            if (file.uploaded) {

                axios.delete(`/articles/${this.id}/files/${file.uid}`).then(() => {

                    this.files = this.files.filter(el => el.uid != file.uid)
                }).catch(error => {
                    this.$notify({
                        type: 'error',
                        message: 'Невозможно удалить файл'
                    })
                })

            } else {

                this.files = this.files.filter(el => el.uid != file.uid)

            }

        },
        initForm() {
            return {
                image: null,
                title: null,
                short_description: null,
                text: null,
                type: 'article',
                active: false,
                seo_title: null,
                seo_description: null,
                published_at: new Date().toDateString(),
                type: 'article'
            }
        },
        save() {

            if (this.isProcess) {
                return
            }

            this.isProcess = true

            this.form['active'] = Number(this.form.active)

            let formData = new FormData();

            for (let key in this.form) {
                if (key != 'files' && this.form[key] != null) {
                    formData.set(key, this.form[key]);
                }
            }

            if (this.files.length) {

                for (let i = 0; i < this.files.length; i++) {
                    formData.set('files[' + i + ']', this.files[i].raw);
                }
            }

            formData.set('action', this.form['action']);

            if (this.id) {
                formData.set('_method', 'PUT');
            }

            const $url = this.id
                ? `/articles/${this.id}`
                : '/articles';

            axios.post($url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                this.isProcess = false

                this.$notify({
                    type: 'success',
                    message: 'Статья создана!'
                })
                this.errors.clearAll()
                this.$router.push('/articles')

            }).catch(error => {
                this.isProcess = false
                console.log(error)
                if (error.response && error.response.status == 422) {
                    this.$notify({
                        type: 'warning',
                        message: error.response.data.message
                    })
                    this.errors.set(error.response.data.errors)
                }
            });

        },

        uploadHandler(blobInfo, success, failure) {

            var formData = new FormData();
            formData.append('file', blobInfo.blob());

            axios.post('/upload', formData).then(response => {
                success(response.data.url)
            }).catch(error => {
                if (error.response && error.response.status == 422) {
                    let msg = (error.response.data && error.response.data.errors.file[0]) || error.response.message
                    failure(msg, { remove: true });
                    return;
                }

                failure('Ошибка', { remove: true });
                return;
            })

        },

        handleOnChangeFile(file) {

            if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.raw.type)) {
                return this.$notify({
                    type: 'warning',
                    message: 'Допускаются изображения с расширением .jpeg, .jpg, .png'
                })
            }

            if (file.raw.size > 2097152) {
                return this.$notify({
                    type: 'warning',
                    message: 'Допускаются изображения е более 2Mb'
                })
            }

            this.form.image = file.raw;
        },

        loadArticle(id) {
            axios.get(`/articles/${id}`).then(res => {
                this.form = Object.assign({}, this.initForm(), res.data)

                if (this.form.files.length) {
                    this.fileList = this.form.files.map(el => {
                        return {
                            'uid': el.id,
                            'name': el.name,
                            'url': el.url,
                            'uploaded': true
                        }
                    })
                }

            }).catch(error => {
                this.$notify({
                    type: 'error',
                    message: 'Не удалось загрузить статью'
                })
            })
        }

    },

    created() {
        if (this.id) {
            this.loadArticle(this.id)
        }
    }
}
</script>

<style>
.avatar {
    max-width: 100%;
    max-height: 100%;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 300px;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
</style>
